<template>
  <div class="bg-yellow-50">
    <div class="max-w-4xl mx-auto p-6">
      <!-- 返回按钮和标题 -->
      <div class="flex items-center justify-between border-b border-yellow-200 pb-6 mb-6">
        <button @click="goBack"
          class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2">
          <ArrowLeftIcon class="h-5 w-5" />
          <span>返回列表</span>
        </button>
      </div>

      <!-- 加载状态 -->
      <div v-if="loading" class="text-center py-8">
        <div class="inline-block animate-spin rounded-full h-8 w-8 border-4 border-yellow-700/50 border-t-transparent">
        </div>
        <p class="mt-2 text-yellow-800/70">加载中...</p>
      </div>

      <!-- 笔记内容 -->
      <div v-else-if="note" class="space-y-6">
        <!-- 编辑模式 -->
        <div v-if="editing" class="space-y-4">
          <div class="relative">
            <textarea v-model="editedContent"
              class="w-full p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 resize-y transition duration-200 ease-in-out min-h-[200px]" />
            <PencilIcon class="h-5 w-5 absolute right-3 top-3 text-yellow-700/50" />
          </div>

          <div class="flex space-x-4">
            <button @click="saveNote"
              class="px-4 py-2 bg-yellow-700/90 hover:bg-yellow-800 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
              :disabled="loading">
              <CheckIcon class="h-5 w-5" />
              <span>保存</span>
            </button>
            <button @click="cancelEdit"
              class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2"
              :disabled="loading">
              <XMarkIcon class="h-5 w-5" />
              <span>取消</span>
            </button>
          </div>
        </div>

        <!-- 展示模式 -->
        <div v-else class="space-y-4">
          <div class="border-b border-yellow-200 pb-6">
            <div class="flex space-x-3">
              <div class="flex-1">
                <p class="text-yellow-900/90 text-lg leading-relaxed whitespace-pre-wrap">{{ note.content }}</p>
                <p class="mt-2 text-sm text-yellow-700/70 flex items-center space-x-1">
                  <ClockIcon class="h-4 w-4" />
                  <span>{{ formatDate(note.created_at) }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="flex space-x-4">
            <button @click="editNote"
              class="px-4 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2">
              <PencilSquareIcon class="h-5 w-5" />
              <span>编辑</span>
            </button>
            <button @click="deleteNote" :disabled="deleting"
              class="px-4 py-2 bg-red-600/70 hover:bg-red-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2">
              <TrashIcon class="h-5 w-5" />
              <span>{{ deleting ? '删除中...' : '删除' }}</span>
            </button>
          </div>
        </div>
      </div>

      <!-- 未找到笔记 -->
      <div v-else class="text-center py-8">
        <ExclamationCircleIcon class="h-12 w-12 mx-auto text-yellow-700/50 mb-2" />
        <p class="text-yellow-700/70">未找到笔记。</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { get, post } from '../utils';

import {
  ArrowLeftIcon,
  PencilSquareIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
  ClockIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/24/outline';

const router = useRouter();
const route = useRoute();

const note = ref(null);
const loading = ref(false);
const deleting = ref(false);
const editing = ref(false);
const editedContent = ref('');

const id = route.params.id;

// 加载笔记详情
const fetchNote = async () => {
  loading.value = true;
  try {
    const token = localStorage.getItem('token');
    const data = await get(`/api/note/detail?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    note.value = data;
    editedContent.value = data.content;
  } catch (error) {
    console.error('Failed to fetch note:', error);
  } finally {
    loading.value = false;
  }
};

// 保存编辑的笔记
const saveNote = async () => {
  loading.value = true;
  try {
    const token = localStorage.getItem('token');
    await post('/api/note/edit', { id, content: editedContent.value }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    note.value.content = editedContent.value;
    editing.value = false;
  } catch (error) {
    console.error('Failed to edit note:', error);
  } finally {
    loading.value = false;
  }
};

// 删除笔记
const deleteNote = async () => {
  if (!confirm('确定要删除这条笔记吗？')) return;

  deleting.value = true;
  try {
    const token = localStorage.getItem('token');
    await post('/api/note/delete', { id }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    router.push('/');
  } catch (error) {
    console.error('Failed to delete note:', error);
  } finally {
    deleting.value = false;
  }
};

// 格式化日期
const formatDate = (dateStr) => {
  return new Date(dateStr).toLocaleString();
};

// 返回到列表页面
const goBack = () => {
  router.push('/');
};

// 进入编辑模式
const editNote = () => {
  editing.value = true;
};

// 取消编辑
const cancelEdit = () => {
  editing.value = false;
  editedContent.value = note.value.content;
};

// 加载笔记详情
onMounted(() => {
  fetchNote();
});
</script>

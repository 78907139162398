<template>
  <div class="bg-yellow-50">
    <div class="max-w-4xl mx-auto p-6 space-y-6">
      <!-- 新增笔记输入区 -->
      <div class="border-b border-yellow-200 pb-6">
        <textarea v-model="newNote" placeholder="写下你的新笔记..."
           class="resize-y w-full h-32 p-3 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 placeholder-yellow-700/30" />
        <button @click="saveNote"
          class="mt-3 px-4 py-2 bg-yellow-700/90 hover:bg-yellow-800 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2">
          <PencilIcon class="h-5 w-5" />
          <span>保存笔记</span>
        </button>
      </div>

      <!-- 搜索区 -->
      <div class="border-b border-yellow-200 pb-6">
        <div class="flex space-x-2">
          <div class="relative flex-1">
            <input v-model="searchTerm" type="text" placeholder="搜索笔记..."
              class="w-full pl-10 pr-4 py-2 bg-yellow-50 border border-yellow-200 rounded-lg focus:ring-1 focus:ring-yellow-300 focus:border-yellow-300 placeholder-yellow-700/30" />
            <MagnifyingGlassIcon class="h-5 w-5 absolute left-3 top-2.5 text-yellow-700/50" />
          </div>
          <button @click="searchNotes(1)"
            class="px-6 py-2 bg-yellow-600/70 hover:bg-yellow-700/70 text-yellow-50 rounded-lg transition duration-200 ease-in-out flex items-center space-x-2 whitespace-nowrap">
            <MagnifyingGlassIcon class="h-5 w-5" />
            <span>搜索</span>
          </button>
        </div>
      </div>

      <!-- 笔记列表 -->
      <div class="space-y-4">
        <div v-if="loading" class="text-center py-8">
          <div
            class="inline-block animate-spin rounded-full h-8 w-8 border-4 border-yellow-700/50 border-t-transparent">
          </div>
          <p class="mt-2 text-yellow-800/70">加载中...</p>
        </div>

        <div v-else-if="Array.isArray(notes) && notes.length > 0" class="space-y-2">
          <div v-for="note in notes" :key="note.id"
            class="py-4 border-b border-yellow-200 hover:bg-yellow-100/30 transition duration-200 ease-in-out">
            <!-- 第一行：笔记内容（可跳转到详情页） -->
            <router-link :to="`/note/${note.id}`" class="block">
              <p class="text-yellow-900/90 text-lg leading-relaxed">
                {{ note.content.length > 100 ? note.content.substring(0, 100) + '...' : note.content }}
              </p>
            </router-link>

            <!-- 第二行：时间和置顶按钮 -->
            <div class="mt-2 text-sm text-yellow-700/70 flex items-center">
              <!-- 时间 -->
              <div class="flex items-center space-x-1 mr-2">
                <ClockIcon class="h-4 w-4" />
                <span>{{ new Date(note.created_at).toLocaleString() }}</span>
              </div>

              <button v-if="!note.pinned" @click="pinNote(note.id)">
                <OutlineBookmarkIcon class="h-4 w-4" />
              </button>
              <button v-else @click="unpinNote(note.id)">
                <SolidBookmarkIcon class="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>

        <div v-else class="text-center py-8 text-yellow-700/70 border-b border-yellow-200">
          没有找到笔记
        </div>
      </div>

      <!-- 分页 -->
      <div class="flex items-center justify-center space-x-4 pt-2" v-if="showPagination">
        <button @click="handlePageChange(currentPage - 1)" :disabled="currentPage === 1"
          class="px-4 py-2 rounded-lg border border-yellow-300 hover:bg-yellow-100/30 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200 ease-in-out flex items-center space-x-2">
          <ChevronLeftIcon class="h-5 w-5 text-yellow-700" />
          <span class="text-yellow-700">上一页</span>
        </button>

        <span class="text-yellow-800/70">
          第 {{ currentPage }} 页
        </span>

        <button @click="handlePageChange(currentPage + 1)" :disabled="!hasNextPage"
          class="px-4 py-2 rounded-lg border border-yellow-300 hover:bg-yellow-100/30 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200 ease-in-out flex items-center space-x-2">
          <span class="text-yellow-700">下一页</span>
          <ChevronRightIcon class="h-5 w-5 text-yellow-700" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { get, post } from '../utils';

import {
  PencilIcon,
  MagnifyingGlassIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  BookmarkIcon as OutlineBookmarkIcon, // 空心书签图标
} from '@heroicons/vue/24/outline';

import { BookmarkIcon as SolidBookmarkIcon } from '@heroicons/vue/24/solid'; // 实心书签图标

const router = useRouter();

const searchTerm = ref('');
const notes = ref([]); // 确保初始值为数组
const loading = ref(false);
const newNote = ref('');
const currentPage = ref(1);
const pageSize = ref(10);
const hasNextPage = ref(false);
const showPagination = ref(false);

// 检查用户是否已登录
onMounted(() => {
  const token = localStorage.getItem('token');
  if (!token) {
    router.push('/login');
  } else {
    loadNotes(currentPage.value);
  }
});

// 加载笔记列表
const loadNotes = async (page = 1) => {
  loading.value = true;
  try {
    const response = await get(`/api/note/list?page=${page}&pageSize=${pageSize.value}`);
    if (Array.isArray(response)) {
      notes.value = response;
      // 判断是否有下一页
      hasNextPage.value = response.length === pageSize.value;
      // 显示分页控件
      showPagination.value = true;
    } else {
      console.error('预期的响应是数组，但收到:', response);
      notes.value = [];
      hasNextPage.value = false;
      showPagination.value = false;
    }
  } catch (error) {
    console.error('加载笔记失败:', error);
    notes.value = [];
    hasNextPage.value = false;
    showPagination.value = false;
  } finally {
    loading.value = false;
  }
};

// 搜索笔记
const searchNotes = async (page = 1) => {
  loading.value = true;
  try {
    const response = await get(`/api/note/search?page=${page}&pageSize=${pageSize.value}&search=${encodeURIComponent(searchTerm.value)}`);
    if (Array.isArray(response)) {
      notes.value = response;
      // 判断是否有下一页
      hasNextPage.value = response.length === pageSize.value;
      // 显示分页控件
      showPagination.value = true;
    } else {
      console.error('预期的响应是数组，但收到:', response);
      notes.value = [];
      hasNextPage.value = false;
      showPagination.value = false;
    }
  } catch (error) {
    console.error('搜索笔记失败:', error);
    notes.value = [];
    hasNextPage.value = false;
    showPagination.value = false;
  } finally {
    loading.value = false;
  }
};

// 保存笔记
const saveNote = async () => {
  if (!newNote.value.trim()) return;
  loading.value = true;
  try {
    await post('/api/note/add', { content: newNote.value });
    newNote.value = '';
    // 重新加载当前页的笔记
    loadNotes(currentPage.value);
  } catch (error) {
    console.error('保存笔记失败:', error);
  } finally {
    loading.value = false;
  }
};

// 处理分页
const handlePageChange = (newPage) => {
  if (newPage < 1) return;
  currentPage.value = newPage;
  if (searchTerm.value) {
    searchNotes(newPage);
  } else {
    loadNotes(newPage);
  }
};

// 监听当前页面变化
watch(currentPage, () => {
  if (searchTerm.value) {
    searchNotes(currentPage.value);
  } else {
    loadNotes(currentPage.value);
  }
});

// 置顶笔记
const pinNote = async (id) => {
  loading.value = true;
  try {
    const response = await post('/api/note/pin', { id });
    if (response.success) {
      // 更新本地笔记状态
      const note = notes.value.find(n => n.id === id);
      if (note) {
        note.pinned = 1;
      }
      // 重新加载笔记以更新排序
      loadNotes(currentPage.value);
    } else {
      console.error('置顶笔记失败:', response.error);
    }
  } catch (error) {
    console.error('置顶笔记失败:', error);
  } finally {
    loading.value = false;
  }
};

// 取消置顶笔记
const unpinNote = async (id) => {
  loading.value = true;
  try {
    const response = await post('/api/note/unpin', { id });
    if (response.success) {
      // 更新本地笔记状态
      const note = notes.value.find(n => n.id === id);
      if (note) {
        note.pinned = 0;
      }
      // 重新加载笔记以更新排序
      loadNotes(currentPage.value);
    } else {
      console.error('取消置顶笔记失败:', response.error);
    }
  } catch (error) {
    console.error('取消置顶笔记失败:', error);
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>
